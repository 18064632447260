import {
  setShowOverlay,
  setInRequest,
  setCallFrom,
  setMessages,
} from "../store/actions";
import store from "./../store/store";
import * as wss from "../utils/wss";
import Peer from "simple-peer";
import { fetchTURNCredentials, getTURNIceServers } from "./turn";
import RecordRTCPromisesHandler from "recordrtc";
import { uploadMediaFile } from "./api";
import { v4 as uuidv4 } from "uuid";
const hdreadyConstraint = {
  audio: true,
  video: {
    width: "640",
    height: "480",
  },
};

const defaultConstraint = {
  audio: true,
  video: {
    width: "480",
    height: "360",
  },
};

const onlyAudioContstraints = {
  audio: true,
  video: false,
};

let localStream;

export const getLocalPreviewAndInitRoomConnection = async (
  isRoomHost,
  identity,
  rooomId = null,
  onlyAudio,
  fromJid = "",
  toJid = ""
) => {
  try {
    await fetchTURNCredentials();
    const constraints = onlyAudio ? onlyAudioContstraints : defaultConstraint;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log("successfully received local stream...");
        localStream = stream;
        console.log(store.getState().isRecordingOn);
        showLocalVideoPreview(localStream);
        //Dispatch an action to hide Overlay
        store.dispatch(setShowOverlay(false));
        isRoomHost
          ? wss.createNewRoom(identity, onlyAudio, fromJid, toJid)
          : wss.joinRoom(identity, rooomId, onlyAudio, fromJid);
      })
      .catch((err) => {
        console.log(
          `Error occurred when trying to get access to local Stream -> ${err}`
        );
      });
  } catch (error) {
    console.log(error);
  }
};

let peers = {};
let streams = [];
//record related
let meetingId;
let recorder;
let remoteRecorder = [];
let chatUsers = [];
let streamIds = [];

export const startSaveLocalStream = async () => {
  remoteRecorder = [];
  streamIds = [];
  meetingId = uuidv4();
  //if (store.getState().isRecordingOn) {
  //console.log("Recording started..");
  recorder = new RecordRTCPromisesHandler(localStream, {
    type: "audio",
  });
  recorder.startRecording();
  // } else {
  //   console.log("recoding is off..");
  // }
};

export const stopSaveLocalStream = async () => {
  //if (!store.getState().isRecordingOn) {
  return new Promise((resolve, reject) => {
    recorder.stopRecording(async function () {
      let blobContent = recorder.getBlob();
      var audioURL = window.URL.createObjectURL(blobContent);
      //convert Blob to file
      // Create a File object from the Blob
      const file = new File([blobContent], `${uuidv4()}.wav`, {
        type: "audio/wav",
      });

      //var file = blobToFile(blobContent, "my-recording.wav");
      //console.log(file);
      //invokeSaveAsDialog(blob);
      const formData = new FormData();
      formData.append("file", file); // Provide a filename
      formData.append("meetingid", meetingId);
      formData.append("jid", store.getState().fromJid);
      formData.append("name", store.getState().identity);
      // await uploadMediaFile(blobContent);
      await uploadMediaFile(formData);
      resolve("Uploaded and Added to Queue");
    });
  });

  // } else {
  //   console.log("recoding is on..");
  // }
};

export const startSaveRemoteStream = async () => {
  //if (store.getState().isRecordingOn) {
  console.log(`Remote Recording started.. ${streams.length}`);
  streams.forEach((stream) => {
    let obj = new RecordRTCPromisesHandler(stream, {
      type: "audio",
    });
    remoteRecorder.push(obj);
    streamIds.push(stream.id);
    remoteRecorder[remoteRecorder.length - 1].startRecording();
  });

  // } else {
  //   console.log("recoding is off..");
  // }
};

export const stopSaveRemoteStream = async () => {
  //if (!store.getState().isRecordingOn) {
  return new Promise((resolve, reject) => {
    console.log(`Remote Recording Stopped.. ${remoteRecorder.length}`);
    let idx = 0;
    remoteRecorder.forEach((recorder, index) => {
      // console.log(streamIds[index]);
      const userInfo = chatUsers.find(
        (user) => user.mediaStreamId === streamIds[index]
      );
      let fromJid = "";
      let fromName = "";
      if (userInfo) {
        fromJid = userInfo.fromJid;
        fromName = userInfo.identity;
        // console.log(`froomJid =  ${fromJid} from Name = ${fromName}`);
      }
      recorder.stopRecording(async function () {
        let blobContent = recorder.getBlob();
        var audioURL = window.URL.createObjectURL(blobContent);
        //convert Blob to file
        // Create a File object from the Blob
        const file = new File([blobContent], `${uuidv4()}.wav`, {
          type: "audio/wav",
        });

        //var file = blobToFile(blobContent, "my-recording.wav");
        //console.log(file);
        //invokeSaveAsDialog(blob);
        const formData = new FormData();
        formData.append("file", file); // Provide a filename
        formData.append("meetingid", meetingId);
        formData.append("jid", fromJid);
        formData.append("name", fromName);
        // await uploadMediaFile(blobContent);
        console.log("Started upload remote Media File...");
        await uploadMediaFile(formData);
        idx++;
        if (idx >= remoteRecorder.length)
          resolve("Uploaded and Added to Queue");
      });
    });
  });

  // } else {
  //   console.log("recoding is on..");
  // }
};

const getConfiguration = () => {
  const turnIceServers = getTURNIceServers();
  if (turnIceServers) {
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
        ...turnIceServers,
      ],
    };
  } else {
    console.warn("Using only STUN Server...");
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    };
  }
};

const messengerChannel = "messenger";

export const prepareNewPeerConnection = (connUserSocketId, isInitiator) => {
  const configuration = getConfiguration();
  peers[connUserSocketId] = new Peer({
    initiator: isInitiator,
    config: configuration,
    stream: localStream,
    channelName: messengerChannel,
  });
  console.log("Figers crossed...waiting for Stream...");

  peers[connUserSocketId].on("signal", (data) => {
    //webRTC offer, webRTC Answer (SDP Information), ice candidates

    const signalData = {
      signal: data,
      connUserSocketId: connUserSocketId,
    };

    wss.signalPeerData(signalData);
  });

  peers[connUserSocketId].on("stream", (stream) => {
    console.log("Stream has Arrived..");
    addStream(stream, connUserSocketId);
    streams = [...streams, stream];
  });

  peers[connUserSocketId].on("data", (data) => {
    const messageData = JSON.parse(data);
    appendNewMessage(messageData);
  });
};

export const handleSignalingData = (data) => {
  //add signaliing data to peer connection
  peers[data.connUserSocketId].signal(data.signal);
};

export const removePeerConnection = (data) => {
  const { socketId } = data;
  const videoContainer = document.getElementById(socketId);
  const videoEle = document.getElementById(`${socketId}-video`);

  if (videoEle && videoContainer) {
    const tracks = videoEle.srcObject.getTracks();
    tracks.forEach((t) => {
      t.stop();
    });
    videoEle.srcObject = null;
    videoContainer.removeChild(videoEle);
    videoContainer.parentNode.removeAttribute("class");
    videoContainer.parentNode.className = "";
    const total_participants = store.getState().participants.length - 1;

    //The following code is for aligning the Participants Video Panel
    //If the number of Participants goes beyond 12, then display as 5 column grid display
    if (total_participants <= 6)
      videoContainer.parentNode.classList.add(
        `grid-container-${total_participants}`
      );
    else videoContainer.parentNode.classList.add(`grid-container-infinite2`);

    videoContainer.parentNode.removeChild(videoContainer);

    console.log(`Disconnect ${data}`);
    if (peers[socketId]) {
      peers[socketId].destroy();
    }
    // delete peers[socketId];
    console.log(`End of Disconnect ${data}`);
  }
};

export const sendLoggedInInfo = (jid, name) => {
  console.log("login event...");
  wss.loggedIn(jid, name);
  store.dispatch(setInRequest(false));
  store.dispatch(setCallFrom(""));
};

export const sendCallRequestAlert = (fromUserName) => {
  //More info need to be sent
  store.dispatch(setInRequest(true));
  store.dispatch(setCallFrom(fromUserName));
};

export const disconnectUser = () => {
  wss.disconnect();
};
/////////////UI Stuff here //////////////////////
const showLocalVideoPreview = (stream) => {
  console.log(store.getState().participants.length);
  const videosContainer = document.getElementById("videos_portal");
  videosContainer.classList.add("grid-container-1");
  const videoContainer = document.createElement("div");
  //videoContainer.classList.add("video_track_container");
  const videoElement = document.createElement("video");

  videoElement.autoplay = true;
  videoElement.muted = true;
  videoElement.playsInline = true;
  videoElement.srcObject = stream;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoContainer.appendChild(videoElement);

  if (store.getState().connectOnlyWithAudio) {
    videoContainer.appendChild(getAudioOnlyLabel());
  } else {
    console.log(store.getState().identity);
    videoContainer.appendChild(getParticipantLabel(store.getState().identity));
  }
  videosContainer.appendChild(videoContainer);
};

const addStream = (stream, connUserSocketId) => {
  //Display incoming stream...here
  console.log(store.getState().participants.length);
  const videosContainer = document.getElementById("videos_portal");
  videosContainer.classList.remove("grid-container-1");
  videosContainer.className = "";
  if (store.getState().participants.length <= 6) {
    videosContainer.classList.add(
      `grid-container-${store.getState().participants.length}`
    );
  } else videosContainer.classList.add(`grid-container-infinite2`);
  const videoContainer = document.createElement("div");
  videoContainer.id = connUserSocketId;
  //videoContainer.classList.add("video_track_container");

  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.srcObject = stream;
  videoElement.playsInline = true;
  videoElement.id = `${connUserSocketId}-video`;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  videoElement.addEventListener("click", () => {
    if (videoElement.classList.contains("full_screen")) {
      videoElement.classList.remove("full_screen");
    } else {
      videoElement.classList.add("full_screen");
    }
  });
  videoContainer.appendChild(videoElement);
  //check if other user connected with only  audio
  const participants = store.getState().participants;
  const participant = participants.find((p) => p.socketId === connUserSocketId);
  chatUsers.push(participant);
  console.log(participant);
  chatUsers[chatUsers.length - 1].mediaStreamId = stream.id;
  console.log(`stream id = ${stream.id}`);

  if (participant?.onlyAudio) {
    videoContainer.appendChild(getAudioOnlyLabel(participant.identity));
  } else {
    videoContainer.appendChild(getParticipantLabel(participant.identity));
    //videoContainer.style.position = "static";
  }

  videosContainer.appendChild(videoContainer);
};

const getAudioOnlyLabel = (identity = "") => {
  const labelContainer = document.createElement("div");
  labelContainer.classList.add("label_only_audio_container");

  const label = document.createElement("p");
  label.classList.add("label_only_audio_text");

  label.innerHTML = `Only audio - ${identity}`;
  labelContainer.appendChild(label);
  return labelContainer;
};

const getParticipantLabel = (identity = "") => {
  const labelContainer = document.createElement("span");
  labelContainer.classList.add("video-overlay");

  const label = document.createElement("p");
  label.classList.add("label_only_audio_text");

  label.innerHTML = `${identity}`;
  labelContainer.appendChild(label);
  return labelContainer;
};

///////Buttons Logic ///////////////

export const toggleMic = (isMuted) => {
  localStream.getAudioTracks()[0].enabled = isMuted ? true : false;
};

export const toggleCamera = (isDiabled) => {
  localStream.getVideoTracks()[0].enabled = isDiabled ? true : false;
};

///////// Messages ////////////////

const appendNewMessage = (messageData) => {
  const messages = store.getState().messages;
  store.dispatch(setMessages([...messages, messageData]));
};

export const sendMessageUsingDataChannel = (messageContent) => {
  const identity = store.getState().identity;
  const messageDateTime = new Date();
  const localMessageData = {
    content: messageContent,
    identity,
    messageCreatedByMe: true,
    sentDateTime: messageDateTime.toJSON(),
  };
  console.log(localMessageData);
  appendNewMessage(localMessageData);

  const messageData = {
    content: messageContent,
    identity,
    sentDateTime: messageDateTime.toJSON(),
  };

  const stringifiedMessageData = JSON.stringify(messageData);
  for (let socketId in peers) {
    try {
      peers[socketId].send(stringifiedMessageData);
    } catch (err) {
      console.log(err);
    }
  }
};
