import React from "react";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import { connect } from "react-redux";
import MenuButton from "./MenuButton";
import ChatButton from "./ChatButton";
import RecordButton from "./RecordButton";

const VideoButtons = (props) => {
  const { connectOnlyWithAudio, isRoomHost } = props;

  return (
    <div className="video_buttons_container">
      <MicButton />
      {!connectOnlyWithAudio && <CameraButton />}
      <LeaveRoomButton />
      {/* {!connectOnlyWithAudio && <SwitchToScreenSharingButton />} */}
      <MenuButton />
      <ChatButton />
      {/* {isRoomHost && <RecordButton />} */}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideoButtons);
