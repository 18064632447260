import React, { useState } from "react";
import record from "./../../resources/images/record.svg";
import stop from "./../../resources/images/stop.svg";

import { Tooltip } from "react-tooltip";
import { connect } from "react-redux";
import { setIsRecordingOn } from "../../store/actions";
import * as webRTCHandler from "../../utils/webRTCHandler";

const RecordButton = (props) => {
  const { setIsRecordingOnAction, isRecordingOn } = props;
  const [isRecordOn, setIsRecordOn] = useState(false);
  const handleRecordButtonPressed = async () => {
    console.log("Record Button clicked...");
    setIsRecordOn(!isRecordOn);
    setIsRecordingOnAction(!isRecordingOn);
    console.log(isRecordingOn);
    if (!isRecordOn) {
      webRTCHandler.startSaveLocalStream();
      webRTCHandler.startSaveRemoteStream();
    } else {
      await webRTCHandler.stopSaveLocalStream();
      await webRTCHandler.stopSaveRemoteStream();
    }
  };
  return (
    <div className="video_button_container">
      <Tooltip id="my-tooltip" />
      {/* <object
        ref={objectRef}
        type="image/svg+xml"
        data={isMicMuted ? micOff : mic}
      ></object> */}
      <img
        className="video_button_image small-img"
        src={isRecordOn ? stop : record}
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleRecordButtonPressed}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={isRecordOn ? "Record On" : "Record Off"}
      />
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRecordingOnAction: (isRecordingOn) =>
      dispatch(setIsRecordingOn(isRecordingOn)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RecordButton);
